.prose pre code {
  @apply rounded-sm border border-dashed border-base-content;
}

.alert {
  @apply text-lg text-white;
}
.badge {
  @apply text-white;
}
.alert-success,
.badge-success {
  @apply bg-success text-success-content;
}
.alert-error,
.badge-error {
  @apply bg-error text-error-content;
}
.alert-info,
.badge-info {
  @apply bg-info text-info-content ;
}
.alert-warning,
.badge-warning {
  @apply bg-warning text-warning-content;
}
.btn {
  @apply text-base;
}
.join > .btn-active, .join > input[type="radio"]:checked.btn {
  @apply bg-accent text-accent-content;
}
.join > .btn:not(:first-child), .join > input[type="radio"]:checked.btn:not(:first-child)  {
  @apply border-l border-base-100;
}
.label {
  @apply p-0 m-0 font-semibold justify-start;
}
.field-hint, .field-error {
  @apply text-sm block;
}
.form-control {
  @apply p-0 mb-6;
}
.form-control.form-error {
  @apply pl-2 border-l-2 border-error;
}
.form-control.form-error textarea {
  @apply textarea-error;
}
.form-control.form-error > input:not([type=radio]),
.form-control.form-error > input:not([type=checkbox]) {
  @apply input-error;
}
.form-control.form-error input[type=radio],
.form-control.form-error input[type=checkbox] {
  @apply border-error;
}
.form-control.form-error .small-label,
.form-control.form-error .field-error,
.form-control.form-error label,
.form-control.form-error legend .label {
  @apply text-error;
}
.input,
.textarea,
.select
{
  @apply my-1;
}
.checkbox {
  @apply checkbox-sm;
}
.radio {
  @apply radio-sm;
}
.toggle {
  @apply toggle-sm rounded-full;
}
.turbo-progress-bar {
  @apply h-1 bg-gradient-to-r from-primary;
}
.rank-1 {
  @apply bg-rank-1 text-rank-1-content;
}
.rank-2 {
  @apply bg-rank-2 text-rank-2-content;
}
.rank-3 {
  @apply bg-rank-3 text-rank-3-content;
}
.rank-4 {
  @apply bg-rank-4 text-rank-4-content;
}
.rank-5 {
  @apply bg-rank-5 text-rank-5-content;
}

