:root {
  --rounded-box: 0.125rem;        /* border-radius for cards and other big elements */
  --rounded-btn: 0.125rem;        /* border-radius for buttons and similar elements */
  --rounded-badge: 1rem;          /* border-radius for badge and other small elements */
  --animation-btn: 0.25s;         /* bounce animation time for button */
  --animation-input: 0.2s;        /* bounce animation time for checkbox, toggle, etc */
  --padding-card: 1rem;           /* default card-body padding */
  --btn-text-case: normal-case;   /* default text case for buttons */
  --navbar-padding: 0.5rem;       /* default padding for navbar */
  --border-btn: 0px;              /* default border size for button */
}

abbr {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Montserrat';
  src: font-url('Montserrat-VariableFont_wght.woff2') format('woff2 supports variations'),
       font-url('Montserrat-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 100 200 300 400 500 600 700 800 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: font-url('Montserrat-Italic-VariableFont_wght.woff2') format('woff2 supports variations'),
       font-url('Montserrat-Italic-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 100 200 300 400 500 600 700 800 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Source Code Pro';
  src: font-url('SourceCodePro-VariableFont_wght.woff2') format('woff2 supports variations'),
       font-url('SourceCodePro-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 200 300 400 500 600 700 800 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: font-url('SourceCodePro-Italic-VariableFont_wght.woff2') format('woff2 supports variations'),
       font-url('SourceCodePro-Italic-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 200 300 400 500 600 700 800 900;
  font-display: swap;
  font-style: italic;
}
